import React from "react";
import { NavLink } from "react-router-dom";
import { LogoGNMini } from "../../assets/img";
import NavBar from "../moleculs/NavBar";
import CardHeader from "react-bootstrap/esm/CardHeader";
import { Image } from "react-bootstrap";

export default function Header() {
  return (
    <CardHeader>
      <div>
        <NavLink to="/">
          <Image
            src={LogoGNMini}
            rounded
            alt="logo"
            loading="lazy"
            height="105px"
          />
        </NavLink>
        <NavBar />
      </div>
    </CardHeader>
  );
}

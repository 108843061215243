import React from "react";
import { Card, Button } from "react-bootstrap";

export default function CardIcon({ img, title, description }) {
  return (
    <Card style={{ width: "25rem" }} >
      <Card.Img src={img} variant="top" alt="logo" />
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <Card.Text>{description}</Card.Text>
        <Button href={title.toLowerCase()}>Ir a {title}</Button>
      </Card.Body>
    </Card>
  );
}

import React from "react";
import { Nav, Navbar, NavLink, Image } from "react-bootstrap";
import { LogoImpacto } from "../../assets/img";

export default function NavBar() {
  return (
    <Navbar.Brand href="/impacto">
      <Image
        alt="logo"
        src={LogoImpacto}
        className="d-inline-block align-top"
        style={{ width: "9rem" }}
        loading="lazy"
      />
      <Nav className="justify-content-end">
        <Nav.Item>
          <NavLink href="/impacto">Inicio</NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink href="/impacto/products">Producs</NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink href="/impacto/staff">Personal</NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink href="/impacto/contactus">Contactenos</NavLink>
        </Nav.Item>
      </Nav>
    </Navbar.Brand>
  );
}

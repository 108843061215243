import React from "react";
import CardIcon from "../moleculs/CardIcon";
import {
  LogoImpacto,
  LogoNixsa,
  LogoAlcons,
  LogoIlcon,
  LogoPVC,
} from "../../assets/img";
import { Container, Stack } from "react-bootstrap";

export default function HomePage() {
  return (
    <Stack
      className="my-auto"
      gap={3}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: "3px solid green",
      }}
    >
      {[
        {
          title: "IMPACTO",
          description: "Importadora y Comecializadora Impacto S.A.",
          img: LogoImpacto,
        },
        {
          title: "NIXSA",
          description: "Nixsa Distribuidora Ferretera",
          img: LogoNixsa,
        },
        {
          title: "ALCONS",
          description: "Ferreteria Alcons tu alternativa constructora",
          img: LogoAlcons,
        },
        {
          title: "ILCON",
          description: "Ilcon Tecnologia Alemana",
          img: LogoIlcon,
        },
        {
          title: "PVC",
          description: "Ferreteria La Casa Del PVC",
          img: LogoPVC,
        },
      ].map((element) => (
        <>
          <CardIcon
            title={element.title}
            description={element.description}
            img={element.img}
          />
        </>
      ))}
    </Stack>
  );
}

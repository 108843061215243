import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "../components/pages/HomePage";
import HomeImpacto from "../components/pages/impacto/HomeImpacto";

export default function RoutesComponent() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage/>} />
        <Route path="/nixsa" element={<header>Inicio Nixsa</header>} />
        <Route path="/nixsa/products" element={<header>Productso de Nixsa</header>} />
        <Route path="/nixsa/contactus" element={<header>Contactos de Nixsa</header>} />
        <Route path="/nixsa/staff" element={<header>Personal de Nixsa</header>} />
        <Route path="/impacto" element={<HomeImpacto/>} />
        <Route path="/impacto/products" element={<header>Productos de Impacto</header>} />
        <Route path="/impacto/contactus" element={<header>Contactos de Impacto</header>} />
        <Route path="/impacto/staff" element={<header>Personal de Impacto</header>} />
        <Route path="/impacto/ilcon" element={<header>Ilcon Impacto</header>} />
      </Routes>
    </Router>
  );
}

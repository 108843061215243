import React from "react";
import Header from "../../organisms/Header";

export default function HomeImpacto() {
  return (
    <>
      <Header />
    </>
  );
}
